<template>
  <div
    :class="step > 0 ? 'classification mt-5' : 'classification-small'"
    class="d-flex flex-column h-100"
  >
    <div
      v-if="step > 0"
    >
      <div class="text-secondary">
        Step {{ step }} of {{ Object.keys(steps).length }}
      </div>
      <div>{{ steps[step] }}</div>
      <b-progress
        :value="step"
        :max="Object.keys(steps).length"
        class="mt-1 mb-4"
        height="0.5rem"
      />
    </div>
    <div
      v-if="step === 0"
      class="d-flex flex-column flex-grow-1 align-items-center justify-content-center"
    >
      <span
        class="mdi mdi-check-circle-outline text-primary"
        style="font-size: 7rem"
      />
      <h1 class="text-primary my-5">
        Welcome to MDR Assessment Tool
      </h1>
      <h3>Enter product name to start assessment</h3>
      <b-form-input
        id="productName"
        v-model="productName"
        placeholder="Product Name"
        class="mt-2 mb-5"
        style="width: 504px"
      />
      <b-button
        :variant="productName ? 'primary' : 'secondary'"
        :disabled="!productName"
        size="lg"
        @click="step++"
      >
        Start
      </b-button>
    </div>

    <div
      v-if="step > 0"
      class="d-flex flex-column h-100 overflow-auto"
    >
      <template v-if="step === 1">
        <div
          v-for="question in answeredQuestions"
          :key="question.id"
          class="d-flex flex-row my-2 align-items-center"
        >
          <div
            :class="question.id !== activeQuestion.id || !!selectedClassification ? 'text-secondary' : 'font-weight-bold'"
          >
            {{ question.description }}
          </div>
          <div
            v-if="answeredQuestions.length > 1 && question.id === activeQuestion.id"
            class="mdi mdi-2rem mdi-chevron-up cursor-pointer"
            @click="revertLastQuestion()"
          />
          <b-form-radio-group
            v-model="question.answer"
            class="d-flex justify-content-end ml-auto"
            :options="questionOptions"
            style="width: 16rem"
            :disabled="question.id !== activeQuestion.id || !!selectedClassification"
            @change="setNextQuestion()"
          />
        </div>

        <div
          v-if="activeQuestion && !selectedClassification"
          style="margin-right: 16rem"
        >
          {{ activeQuestion.note || defaultNote }}
        </div>

        <div
          v-if="selectedClassification"
          class="font-weight-bold"
          style="margin-right: 16rem"
        >
          {{ productName }} is classified as a {{ selectedClassification.class }} device. ({{ selectedClassification.rule }})
        </div>
      </template>
      <template v-if="step === 2">
        <div
          v-for="question in answeredSpecialRuleQuestions"
          :key="question.id"
          class="d-flex flex-row my-2 align-items-center"
        >
          <div
            :class="question.id !== activeSpecialRuleQuestion.id || !!selectedSpecialRuleClassification ? 'text-secondary' : 'font-weight-bold'"
          >
            {{ question.description }}
          </div>
          <div
            v-if="(answeredSpecialRuleQuestions.length > 1 || selectedSpecialRuleClassification) && question.id === activeSpecialRuleQuestion.id"
            class="mdi mdi-2rem mdi-chevron-up cursor-pointer"
            @click="revertLastSpecialRuleQuestion()"
          />
          <b-form-radio-group
            v-model="question.answer"
            class="d-flex justify-content-end ml-auto"
            :options="questionOptions"
            style="width: 16rem"
            :disabled="question.id !== activeSpecialRuleQuestion.id || !!selectedSpecialRuleClassification"
            @change="setNextSpecialRuleQuestion()"
          />
        </div>

        <div
          v-if="activeSpecialRuleQuestion && !selectedSpecialRuleClassification"
          style="margin-right: 16rem"
        >
          {{ activeSpecialRuleQuestion.note || defaultNote }}
        </div>

        <div
          v-if="selectedSpecialRuleClassification"
          class="font-weight-bold"
          style="margin-right: 16rem"
        >
          {{ productName }} is classified as a {{ selectedSpecialRuleClassification.class }} device. ({{ selectedSpecialRuleClassification.rule }})
        </div>

        <div v-if="activeSpecialRuleQuestion && activeSpecialRuleQuestion.answer === 'yes' && activeSpecialRuleQuestion.id === 62">
          <div class="d-flex flex-row align-items-end mt-4 mb-2">
            <label
              for="ancillaryAction"
              style="width: 120px"
            >Ancillary Action</label>
            <b-form-input
              id="ancillaryAction"
              v-model="ancillaryAction"
              placeholder="Ancillary Action"
              style="width: 504px"
              class="ml-4"
            />
          </div>
          <div class="d-flex flex-row align-items-end">
            <label
              for="substanceName"
              style="width: 120px"
            >Substance Name</label>
            <b-form-input
              id="substanceName"
              v-model="substanceName"
              placeholder="Substance Name"
              style="width: 504px"
              class="ml-4"
            />
          </div>
        </div>
      </template>
      <template v-if="step === 3">
        <b-form-group label="Is the device delivered sterile?">
          <b-form-radio-group
            v-model="isSterile"
            :options="[{ text: 'Yes', value: true }, { text: 'No', value: false }]"
            stacked
          />
        </b-form-group>
        <b-form-group
          v-if="isSterile"
          label="How is sterilization done?"
        >
          <v-select
            v-model="selectedSterilization"
            :options="sterilizations"
            :reduce="option => option.value"
            style="width: 40%"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Does your device contains the following materials?">
          <b-form-checkbox-group
            v-model="selectedMaterials"
            :options="materials"
            stacked
          />
        </b-form-group>
        <b-form-group label="Which is your target region?">
          <b-form-radio-group
            v-model="selectedRegion"
            :options="regions"
            stacked
          />
        </b-form-group>
      </template>

      <template v-if="step === 4">
        <b-card
          no-body
          class="border-0"
        >
          <b-card-header
            v-b-toggle="'classification-result'"
            class="d-flex border-0 cursor-pointer px-2 pt-0 bg-white"
          >
            <h4 class="font-weight-bold">
              Classification
            </h4>
            <i class="when-opened mdi mdi-2rem ml-auto mdi-chevron-up" />
            <i class="when-closed mdi mdi-2rem ml-auto mdi-chevron-down" />
          </b-card-header>
          <b-collapse
            id="classification-result"
            visible
          >
            <b-card-body class="py-0 px-4">
              <div>
                {{ productName }} is a medical device per MDR 2017/745, and falls within risk classification {{ selectedClassification.class }}, following {{ selectedClassification.rule }} per Annex VIII of Council Regulation 2017/745 concerning medical devices per MDR.
              </div>
              <div>
                The product is regarded as medical device as defined in MDR 2017/745, article 1.
              </div>
              <div class="font-weight-bold mt-2">
                Classification Rationale
              </div>
              <div>
                {{ classificationRationale }}
              </div>
              <div class="my-2">
                {{ formattedResultDescription }}
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card
          no-body
          class="border-0 mt-4"
        >
          <b-card-header
            v-b-toggle="'standards-result'"
            class="d-flex border-0 cursor-pointer px-2 pt-0 bg-white"
          >
            <h4 class="font-weight-bold">
              Applicable Standards List for {{ productName }}
            </h4>
            <i class="when-opened mdi mdi-2rem ml-auto mdi-chevron-up" />
            <i class="when-closed mdi mdi-2rem ml-auto mdi-chevron-down" />
          </b-card-header>
          <b-collapse
            id="standards-result"
          >
            <b-card-body class="py-0 px-4">
              <b-table
                :items="selectedIsoNames"
                :fields="[{ key: 'iso', label: 'Identifier', thClass: 'w-25' }, { key: 'name', label: 'Title' }]"
              />
              <b-button
                class="d-flex ml-auto mr-4 mdi mdi-download"
                variant="primary"
                @click="download('ASL.docx')"
              />
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card
          no-body
          class="border-0 mt-4"
        >
          <b-card-header
            v-b-toggle="'gspr-result'"
            class="d-flex border-0 cursor-pointer px-2 pt-0 bg-white"
          >
            <h4 class="font-weight-bold">
              GSPR checklist
            </h4>
            <i class="when-opened mdi mdi-2rem ml-auto mdi-chevron-up" />
            <i class="when-closed mdi mdi-2rem ml-auto mdi-chevron-down" />
          </b-card-header>
          <b-collapse
            id="gspr-result"
          >
            <b-card-body class="py-0 px-4">
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th class="w-50">
                      Requirement
                    </b-th>
                    <b-th>Applicability</b-th>
                    <b-th>Applicable Standards</b-th>
                    <b-th>Qualification / Compliance</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>
                      1. Devices shall achieve the performance intended by their manufacturer and shall be designed and manufactured in such a way that, during normal conditions of use, they are suitable for their intended purpose.
                      <br>
                      They shall be safe and effective and shall not compromise the clinical condition or the safety of patients, or the safety and health of users or, where applicable, other persons, provided that any risks which may be associated with their use constitute acceptable risks when weighed against the benefits to the patient and are compatible with a high level of protection of health and safety, taking into account the generally acknowledged state of the art.
                    </b-td>
                    <b-td>Yes</b-td>
                    <b-td />
                    <b-td />
                  </b-tr>
                  <b-tr>
                    <b-td>
                      2. The requirement in this Annex to reduce risks as far as possible means the reduction of risks as far as possible without adversely affecting the benefit-risk ratio.
                    </b-td>
                    <b-td>Yes</b-td>
                    <b-td />
                    <b-td />
                  </b-tr>
                  <b-tr>
                    <b-td>
                      3. Manufacturers shall establish, implement, document and maintain a risk management system.
                      <br>
                      Risk management shall be understood as a continuous iterative process throughout the entire lifecycle of a device, requiring regular systematic updating. In carrying out risk management manufacturers shall:
                      <br>
                      a) establish and document a risk management plan for each device;
                      <br>
                      b) identify and analyse the known and foreseeable hazards associated with each device;
                      <br>
                      c) estimate and evaluate the risks associated with, and occurring during, the intended use and during reasonably foreseeable misuse;
                      <br>
                      d) eliminate or control the risks referred to in point (c) in accordance with the requirements of item "Order of Risk Controls";
                      <br>
                      e) evaluate the impact of information from the production phase and, in particular, from the post-market surveillance system, on hazards and the frequency of occurrence thereof, on estimates of their associated risks, as well as on the overall risk, benefit- risk ratio and risk acceptability; and
                      <br>
                      f) based on the evaluation of the impact of the information referred to in point (e), if necessary amend control measures in line with the requirements of "Order of Risk Controls".
                    </b-td>
                    <b-td>Yes</b-td>
                    <b-td />
                    <b-td />
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                class="d-flex ml-auto mr-4 mdi mdi-download"
                variant="primary"
                @click="download('MDR GSPR Checklist.docx')"
              />
            </b-card-body>
          </b-collapse>
        </b-card>
      </template>
    </div>
    <div
      class="d-flex w-100 my-4"
    >
      <template v-if="step === 1">
        <b-button
          class="ml-auto mr-2"
          variant="outline-primary"
          @click="step--"
        >
          Previous
        </b-button>
        <b-button
          :variant="selectedClassification ? 'primary' : 'secondary'"
          :disabled="!selectedClassification"
          @click="step++"
        >
          Next
        </b-button>
      </template>
      <template v-if="step === 2">
        <b-button
          class="ml-auto mr-2"
          variant="outline-primary"
          @click="step--"
        >
          Previous
        </b-button>
        <b-button
          :variant="selectedSpecialRuleClassification ? 'primary' : 'secondary'"
          :disabled="!selectedSpecialRuleClassification"
          @click="step++"
        >
          Next
        </b-button>
      </template>
      <template v-if="step === 3">
        <b-button
          class="ml-auto mr-2"
          variant="outline-primary"
          @click="step--"
        >
          Previous
        </b-button>
        <b-button
          variant="primary"
          @click="step++"
        >
          Next
        </b-button>
      </template>
      <template v-if="step === 4">
        <b-button
          class="ml-auto mr-2"
          variant="outline-primary"
          @click="step--"
        >
          Previous
        </b-button>
        <b-button
          variant="primary"
          @click="initiateClassification()"
        >
          Done
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DOWNLOAD_AND_SAVE_DOCUMENT } from '@/store/storage/action.types'

export default {
  data () {
    return {
      ancillaryAction: undefined,
      answeredQuestions: [],
      answeredSpecialRuleQuestions: [],
      defaultIsos: [
        'ISO TC159',
        'ISO TC194',
        'ISO TC210'
      ],
      defaultNote: 'The Medical Device Regulation (MDR) EU 2017/745 defines in its Annex VIII the classification rules for medical devices.',
      isSterile: undefined,
      productName: undefined,
      questionOptions: [{
        label: 'Yes',
        text: 'Yes',
        value: 'yes'
      }, {
        label: 'No',
        text: 'No',
        value: 'no'
      }],
      resultDescription: '',
      selectedClassification: undefined,
      selectedSpecialRuleClassification: undefined,
      selectedMaterials: [],
      selectedRegion: undefined,
      selectedSterilization: undefined,
      step: 0,
      steps: {
        1: 'Classification',
        2: 'Special Rules',
        3: 'Additional Questions',
        4: 'Result'
      },
      substanceName: undefined
    }
  },

  computed: {
    activeQuestion () {
      return this.answeredQuestions.slice(-1)[0]
    },

    activeSpecialRuleQuestion () {
      return this.answeredSpecialRuleQuestions.slice(-1)[0]
    },

    classificationRationale () {
      if (this.selectedSpecialRuleClassification) {
        return this.classificationRationales.find(cr => cr.rule === this.selectedSpecialRuleClassification.rule).classification_rationale
      }
      return null
    },

    formattedResultDescription () {
      if (this.resultDescription) {
        return this.resultDescription.replaceAll('%product%', this.productName).replaceAll('%ancillary_action%', this.ancillaryAction).replaceAll('%substance_name%', this.substanceName)
      }
      return null
    },

    selectedIsos () {
      if (this.selectedSterilization) {
        return this.defaultIsos.concat(JSON.parse(this.selectedSterilization))
      }
      return this.defaultIsos
    },

    selectedIsoNames () {
      return this.selectedIsos.reduce((acc, curr) => {
        acc.push(this.isos.find(iso => iso.iso === curr))
        return acc
      }, [])
    },

    ...mapGetters(['classification', 'classificationRationales', 'isos', 'materials', 'regions', 'specialRules', 'sterilizations'])
  },

  mounted () {
    this.initiateClassification()
  },

  methods: {
    async download (key) {
      console.log(key)
      await this.$store.dispatch(DOWNLOAD_AND_SAVE_DOCUMENT, { key, name: key })
    },

    initiateClassification () {
      this.classification.forEach(question => {
        question.answer = undefined
      })
      this.specialRules.forEach(question => {
        question.answer = undefined
      })
      this.answeredQuestions = [this.classification[0]]
      this.answeredSpecialRuleQuestions = [this.specialRules[0]]
      this.isSterile = undefined
      this.resultDescription = ''
      this.selectedClassification = undefined
      this.selectedSpecialRuleClassification = undefined
      this.selectedMaterials = []
      this.selectedRegion = []
      this.selectedSterilization = undefined
      this.step = 0
      this.productName = undefined
      this.ancillaryAction = undefined
      this.substanceName = undefined
    },

    getQuestionById (id) {
      return this.classification.find(question => question.id === id)
    },

    getSpecialRuleQuestionById (id) {
      return this.specialRules.find(question => question.id === id)
    },

    async setNextQuestion () {
      await this.$nextTick()
      const selectedAnswer = this.activeQuestion.answer === 'yes' ? this.activeQuestion.yes : this.activeQuestion.no
      const parsedAnswer = parseInt(selectedAnswer)

      if (isNaN(parsedAnswer)) {
        this.selectedClassification = {
          class: selectedAnswer,
          rule: this.activeQuestion['rule_' + this.activeQuestion.answer]
        }
        this.resultDescription = this.activeQuestion['result_description_' + this.activeQuestion.answer]
      } else {
        this.answeredQuestions.push(this.getQuestionById(parsedAnswer))
      }
    },

    async setNextSpecialRuleQuestion () {
      await this.$nextTick()
      const selectedAnswer = this.activeSpecialRuleQuestion.answer === 'yes' ? this.activeSpecialRuleQuestion.yes : this.activeSpecialRuleQuestion.no
      const parsedAnswer = parseInt(selectedAnswer)

      if (isNaN(parsedAnswer)) {
        this.selectedSpecialRuleClassification = {
          class: selectedAnswer === 'No change in classification' ? this.selectedClassification.class : selectedAnswer,
          rule: this.activeSpecialRuleQuestion['rule_' + this.activeSpecialRuleQuestion.answer] || this.selectedClassification.rule
        }
        this.resultDescription = this.activeSpecialRuleQuestion['result_description_' + this.activeSpecialRuleQuestion.answer]
      } else {
        this.answeredSpecialRuleQuestions.push(this.getSpecialRuleQuestionById(parsedAnswer))
      }
    },

    revertLastQuestion () {
      if (this.selectedClassification) {
        this.activeQuestion.answer = undefined
        this.selectedClassification = undefined
      } else {
        this.answeredQuestions.pop()
        this.activeQuestion.answer = undefined
      }
    },

    revertLastSpecialRuleQuestion () {
      if (this.selectedSpecialRuleClassification) {
        this.activeSpecialRuleQuestion.answer = undefined
        this.selectedSpecialRuleClassification = undefined
      } else {
        this.answeredSpecialRuleQuestions.pop()
        this.activeSpecialRuleQuestion.answer = undefined
      }
    }
  }
}
</script>

<style scoped>
.classification {
  min-height: 80vh;
  margin-left: 20%;
  margin-right: 20%;
}

.classification-small {
  min-height: 50vh;
  margin-left: 20%;
  margin-right: 20%;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
